import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "How to Expect an Exception With Phpunit",
  "author": "tzookb",
  "type": "post",
  "date": "2020-01-16T13:50:08.000Z",
  "url": "/how-to-expect-an-exception-with-phpunit",
  "categories": ["php", "testing", "phpuniy"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Very simple test case I wanted to share that I notice a lot.`}</p>
    <p>{`When you are testing your code, be sure that you really get an exception and only expect in the catch clause.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`//Always passes test
public function example_test()
{   
    try {
        $this->syncCustomer->handle($this->itemData);
    } catch(\\Exception $ex) {
        $this->assertEquals(
            'Please set Shop for customer mapper',
            $ex->getMessage()
        );
    }
}
`}</code></pre>
    <p>{`As you can see above the test will pass in caase there was an exception from the handle call.
But it will pass even if no exception were thrown from the handle call.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`// passes only if really got an exception
public function example_test()
{   
    $this->expectException(\\Exception::class);
    $this->expectExceptionMessage('Please set Shop for customer mapper');
    $this->syncCustomer->handle($this->itemData);
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      